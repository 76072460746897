import React, { Component, Fragment } from 'react';
import { arrayOf, object, string } from 'prop-types';
import classNames from 'classnames';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';

import Medicine from '../../images/icon-medicine.png';
import Cross from '../../images/cross-close.png';

class SidebarMenu extends Component {
  state = {
    open: false,
  };

  static propTypes = {
    title: string.isRequired,
    medications: arrayOf(object).isRequired,
  };

  handleToggle = (e) => {
    const { open } = this.state;

    e.preventDefault();
    this.setState({ open: !open });
  };

  render() {
    const { open } = this.state;
    const { title, medications } = this.props;
    const overlayClasses = classNames('c-nav-overlay', {
      active: open,
    });

    return (
      <Fragment>
        <Helmet
          bodyAttributes={{
            'data-sidebar-medication-open': open,
          }}
        />
        <div className="sidebar sidebar-active">
          <button type="button" onClick={this.handleToggle} className="close-panel">
            <picture>
              <source media="(max-width: 768px)" srcSet={Cross} />
              <img src={Cross} alt="ZooPharm" />
            </picture>
          </button>

          <h3>{title}</h3>
          {medications.length !== 0 ? (
            <ul>
              {medications.map((item, key) => (
                <li key={key.toString()}>
                  <Link to={`/medication/${item.slug}/`} activeClassName="active">
                    {item.name}
                    <small>{item.presentation}</small>
                  </Link>
                </li>
              ))}
            </ul>
          ) : (
            <p>There are no medications available</p>
          )}
        </div>
        <div>
          <button type="button" onClick={this.handleToggle} className="button-sidebar">
            <picture>
              <source media="(max-width: 768px)" srcSet={Medicine} />
              <img src={Medicine} alt="ZooPharm" />
            </picture>
          </button>
        </div>
        <div role="presentation" onClick={this.handleToggle} className={overlayClasses} />
      </Fragment>
    );
  }
}

export default SidebarMenu;
