import React from 'react';
import WedgewoodLogo from '../../images/wedgewood-logo-green.png';
import Card from '../card/card';

const WedgewoodCompanionCard = () => (
  <Card>
    <div className="c-wedgewood-card">
      <div className="c-wedgewood-card--image">
        <img src={WedgewoodLogo} alt="Wedgewood Pharmacy" />
      </div>
      <div className="c-wedgewood-card--content">
        <h4 style={{ color: '#248596', fontSize: 18 }}>
          ZooPharm is now a wholly-owned subsidiary of Wedgewood Pharmacy
        </h4>
        <p style={{ fontSize: 15 }}>
          <strong>
            If you’re looking for formulations not available from ZooPharm, you will probably find
            them at Wedgewood Pharmacy... the largest animal-health compounding pharmacy in the U.S.
          </strong>
        </p>
        <p style={{ fontSize: 15 }}>
          Over 80% of the nation&apos;s veterinarians enjoy our fast, free shipping,
          industry-leading digital tools, dedicated account management team, rigorous quality
          standards backed by a team of experts, and an extensive formulary of over 40,000
          preparations.
        </p>
        <p style={{ marginBottom: 0 }}>
          <a className="c-button--secondary" href="https://www.wedgewoodpharmacy.com/">
            Visit Wedgewood Pharmacy
          </a>
        </p>
      </div>
    </div>
  </Card>
);

export default WedgewoodCompanionCard;
