import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import {
  arrayOf, shape, string, func,
} from 'prop-types';

class AutoComplete extends Component {
  static propTypes = {
    renderSuggestion: func.isRequired,
    name: string.isRequired,
    placeholder: string.isRequired,
    className: string.isRequired,
    data: arrayOf(shape({})).isRequired,
  };

  constructor() {
    super();
    this.state = {
      value: '',
      suggestions: [],
    };
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  getSuggestions = (value) => {
    const { data } = this.props;
    const escapedValue = this.escapeRegexCharacters(value.trim());

    if (escapedValue === '') return [];

    const regex = new RegExp(`^${escapedValue}`, 'i');

    return data
      .filter(item => regex.test(item.name))
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name < b.name) {
          return 1;
        }
        return 0;
      });
  };

  renderSuggestion = (suggestion) => {
    const { renderSuggestion } = this.props;

    return renderSuggestion(suggestion);
  };

  escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  render() {
    const { name, placeholder, className } = this.props;
    const { value, suggestions } = this.state;
    const inputProps = {
      name,
      value,
      className,
      placeholder,
      onChange: this.onChange,
    };

    return (
      <Autosuggest
        inputProps={inputProps}
        suggestions={suggestions}
        renderSuggestion={this.renderSuggestion}
        getSuggestionValue={suggestion => suggestion.name}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
      />
    );
  }
}

export default AutoComplete;
