import React from 'react';
import { Link } from 'gatsby';
import { string } from 'prop-types';
import Card from '../card/card';

const propTypes = {
  title: string.isRequired,
  text: string.isRequired,
  to: string.isRequired,
  image: string.isRequired,
};

const KitCard = ({
  title, text, to, image,
}) => (
  <Card className="no-padding">
    <div className="c-kit-card">
      <div className="c-kit-card--detail">
        <h4>{title}</h4>
        <p>{text}</p>
        <Link to={to} className="c-button--link">
          Learn More
        </Link>
      </div>
      <div className="c-kit-card--image">
        <picture>
          <source media="(max-width: 768px)" srcSet={image} />
          <img src={image} alt="ZooPharm" />
        </picture>
      </div>
    </div>
  </Card>
);

KitCard.propTypes = propTypes;
export default KitCard;
