import { graphql, Link } from 'gatsby';
import { shape } from 'prop-types';
import React, { Component, Fragment } from 'react';
import AutoComplete from '../components/auto-complete/auto-complete';
import Card from '../components/card/card';
import Grid from '../components/grid/grid';
import GridItem from '../components/grid/item';
import Hero from '../components/hero/hero';
import KitCard from '../components/kit-card/kit-card';
import Layout from '../components/layout';
import SidebarMedications from '../components/sidebar-medications/sidebar-medications';
import WedgewoodCompanionCard from '../components/wedgewood/wedgewood-card';
import BamKit from '../images/bam-kit-img.png';
import MedIconC1 from '../images/med-icon-c1.png';
import MedIconRetinaC1 from '../images/med-icon-c1@2x.png';
import MedIconC2 from '../images/med-icon-c2.png';
import MedIconRetinaC2 from '../images/med-icon-c2@2x.png';
import MedIconC3 from '../images/med-icon-c3.png';
import MedIconRetinaC3 from '../images/med-icon-c3@2x.png';
import MedIconC4 from '../images/med-icon-c4.png';
import MedIconRetinaC4 from '../images/med-icon-c4@2x.png';
import MedIconDefault from '../images/med-icon.png';
import MedIconRetinaDefault from '../images/med-icon@2x.png';
import formatGraphqlData from '../utils/gatsbyGraphql';


class CategoryTemplate extends Component {
  static propTypes = {
    data: shape({}).isRequired,
  };

  state = {
    results: null,
    searching: false,
  };

  conbineData = (medications, allSpecies) => medications.reduce((acc, medication) => {
    const { admguidelines, ...allData } = medication;
    const species = admguidelines
      .map(specieId => (specieId.specie ? specieId.specie : null))
      .map((id) => {
        if (!id) return null;

        const { name } = allSpecies.find(specie => id !== null && specie.strapiId === id);
        return name;
      })
      .filter(el => el !== null);

    return acc.concat({ ...allData, species });
  }, []);

  handleSubmit = (e) => {
    e.preventDefault();

    const { data } = this.props;
    const { allStrapiMedication, allStrapiAnimalspecie } = data;

    const medications = allStrapiMedication && allStrapiAnimalspecie
      ? this.conbineData(
        formatGraphqlData(allStrapiMedication),
        formatGraphqlData(allStrapiAnimalspecie),
      )
      : [];

    const results = medications.filter((medication) => {
      const { name, species } = medication;
      const { entrySpecie, entryMedication } = e.target;

      if (entrySpecie.value !== '') {
        return (
          name.toLowerCase().includes(entryMedication.value.toLowerCase())
          && species.find(specie => specie.toLowerCase().includes(entrySpecie.value.toLowerCase()))
        );
      }

      return name.toLowerCase().includes(entryMedication.value.toLowerCase());
    });

    this.setState({ searching: true });

    setTimeout(() => {
      this.setState({
        results,
        searching: false,
      });
    }, 800);
  };

  render() {
    const { data } = this.props;
    const { strapiAnimalcategory, allStrapiMedication, allStrapiAnimalspecie } = data;
    const { results, searching } = this.state;

    const isEmptyObj = obj => Object.values(obj).every(x => x === null || x === '');

    const allMedications = allStrapiMedication ? formatGraphqlData(allStrapiMedication) : [];
    const allSpecies = allStrapiAnimalspecie ? formatGraphqlData(allStrapiAnimalspecie) : [];

    return (
      <Layout title={strapiAnimalcategory.name} theme={strapiAnimalcategory.slug}>
        <Hero
          backgroundImage={strapiAnimalcategory.slug}
          headline={strapiAnimalcategory.name}
          tagline={strapiAnimalcategory.shortDescription || ''}
        >
          <form
            onSubmit={this.handleSubmit}
            className={`c-search-form ${searching ? 'spinner spinner--small' : ''}`}
          >
            <div className="form-group">
              <AutoComplete
                name="entryMedication"
                className="form-control"
                placeholder="Formulation Name"
                data={allMedications}
                renderSuggestion={suggestion => (
                  <Link to={`/medication/${suggestion.slug}`}>
                    {suggestion.name}
                    {' '}
                    <small>{`${suggestion.presentation}`}</small>
                  </Link>
                )}
              />
            </div>
            <div className="form-group">
              <AutoComplete
                name="entrySpecie"
                placeholder="Species"
                className="form-control"
                data={allSpecies}
                renderSuggestion={suggestion => <p>{suggestion.name}</p>}
              />
            </div>
            <button type="submit" className="c-button--primary c-search-form__submit">
              Search
            </button>
          </form>
        </Hero>
        <section className="content-wrapper">
          <Grid>
            <GridItem sm={4} md={3}>
              <SidebarMedications title="All Formulations" medications={allMedications} />
            </GridItem>
            <GridItem sm={8} md={9}>
              {results && (
                <Grid justifyContent="center" alignItems="center">
                  <GridItem md={12}>
                    <div className="c-search-result">
                      {results.length !== 0 ? (
                        results.map((item, key) => {
                          let MedIcon = null;
                          let MedIconRetina = null;

                          switch (parseInt(item.deaSchedule, 10)) {
                            case 1:
                              MedIcon = MedIconC1;
                              MedIconRetina = MedIconRetinaC1;
                              break;
                            case 2:
                              MedIcon = MedIconC2;
                              MedIconRetina = MedIconRetinaC2;
                              break;
                            case 3:
                              MedIcon = MedIconC3;
                              MedIconRetina = MedIconRetinaC3;
                              break;
                            case 4:
                              MedIcon = MedIconC4;
                              MedIconRetina = MedIconRetinaC4;
                              break;
                            default:
                              MedIcon = MedIconDefault;
                              MedIconRetina = MedIconRetinaDefault;
                              break;
                          }

                          return (
                            <Link
                              to={`/medication/${item.slug}/`}
                              className="c-result-item"
                              key={key.toString()}
                            >
                              <picture>
                                <source media="(max-width: 768px)" srcSet={MedIconRetina} />
                                <img src={MedIcon} alt="ZooPharm" />
                              </picture>
                              <Card>
                                <p className="c-result-item__title">{item.name}</p>
                                <p className="c-result-item__subtitle">{item.presentation}</p>
                                <ul className="c-result-item__details">
                                  {/* <li>{item.productCategory}</li> */}
                                  <li>{item.releaseCategory}</li>
                                </ul>
                              </Card>
                            </Link>
                          );
                        })
                      ) : (
                        <h2 className="no-result-alert">
                          No results found matching your criteria.
                        </h2>
                      )}
                    </div>
                    <hr />
                  </GridItem>
                </Grid>
              )}

              <p>{strapiAnimalcategory.longDescription}</p>
              {(strapiAnimalcategory.specificUsesDescription
                || strapiAnimalcategory.specificUsesItems[0] !== '') && (
                  <Fragment>
                    {strapiAnimalcategory.specificUsesDescription && (
                      <p>{strapiAnimalcategory.specificUsesDescription}</p>
                    )}
                    {strapiAnimalcategory.specificUsesItems && (
                      <ul>
                        {strapiAnimalcategory.specificUsesItems.map((item, key) => (
                          <li key={key.toString()}>{item}</li>
                        ))}
                      </ul>
                    )}
                  </Fragment>
              )}
              {(strapiAnimalcategory.slug === 'wildlife'
                || strapiAnimalcategory.slug === 'zoo-animals') && (
                  <GridItem md={5} justifyContent="center" alignItems="center">
                    <KitCard
                      title="BAM™ Kit"
                      text="BAM™ is a patented, unique and safe anesthesia combination of Butorphanol tartrate, Azaperone tartrate and Medetomidine hydrochloride that is used to immobilize a broad range of species. All three of these pharmaceuticals seem to be “co-synergistic” bringing the best attributes of each at the lowest effective dose rate. BAM™ provides smooth induction times that are equal to or shorter than Telazol/Xylazine, as well as quick reversal times (ranging from 5-10 minutes, fully reversed). This highly effective product has been used in hundreds of immobilizations and controlled studies with excellent results."
                      to="/medication/bam-kit/"
                      image={BamKit}
                    />
                  </GridItem>
              )}
              <WedgewoodCompanionCard />
              {(strapiAnimalcategory.usefulDescription
                || !isEmptyObj(strapiAnimalcategory.usefulLinks[0])) && (
                  <Fragment>
                    <h3>Useful Links and Resources</h3>
                    {strapiAnimalcategory.usefulDescription && (
                      <p>{strapiAnimalcategory.usefulDescription}</p>
                    )}
                    {strapiAnimalcategory.usefulLinks && (
                      <ul>
                        {strapiAnimalcategory.usefulLinks.map((item, key) => (
                          <li key={key.toString()}>
                            <a href={item.link} target="_blank" rel="noopener noreferrer">
                              {item.text}
                            </a>
                          </li>
                        ))}
                      </ul>
                    )}
                  </Fragment>
              )}
            </GridItem>
          </Grid>
        </section>
      </Layout>
    );
  }
}

export default CategoryTemplate;
export const query = graphql`
  query CategoryTemplate($id: String!) {
    strapiAnimalcategory(id: { eq: $id }) {
      id
      slug
      name
      shortDescription
      longDescription
      usefulDescription
      specificUsesDescription
      specificUsesItems
      usefulLinks {
        text
        link
      }
    }

    allStrapiMedication(sort: { fields: name, order: ASC }) {
      edges {
        node {
          name
          slug
          deaSchedule
          presentation
          productCategory
          releaseCategory
          admguidelines {
            specie
          }
        }
      }
    }

    allStrapiAnimalspecie {
      edges {
        node {
          strapiId
          name
        }
      }
    }
  }
`;
